<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">交換學生計畫學校一覽表</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper form exchange_school" v-if="ExchangeSchoolData">
      <div class="container">
        <div>
          <h1>
            交換學生計畫學校一覽表
          </h1>
          <div class="box search_box">
            <div class="form-group">
              <Select2 class="form-control" v-model="CountryId" :options="Countrys"
                :settings="{ placeholder: '請選擇國家' }" />
              <label for=""><span>國家</span></label>
            </div>
            <div class="form-group">
              <Select2 class="form-control" v-model="EId" :options="Events"
                :settings="{ placeholder: '請選擇梯次' }" />
              <label for=""><span>梯次</span></label>
            </div>
            <div class="form-group">
              <div class="btn_box">
                <button @click="loadList(1)">搜尋</button>
              </div>
            </div>
          </div>
          <div class="box">
            <table>
              <thead>
                <tr>
                  <th class="basis_75"></th>
                  <th class="basis_75">洲別</th>
                  <th class="basis_75">國家</th>
                  <th>學校名稱</th>
                  <th class="basis_80">上學期<br>名額</th>
                  <th class="basis_80">下學期<br>名額</th>
                  <th class="basis_80">一學年<br>名額</th>
                  <th class="basis_90">詳細說明</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="ExchangeSchool in ExchangeSchools">
                  <tr>
                    <td data-th="" class="basis_75">{{ ExchangeSchool.CSId }}</td>
                    <td data-th="洲別" class="basis_75">{{ ExchangeSchool.Continent }}</td>
                    <td data-th="國家" class="basis_75">{{ ExchangeSchool.Country }}</td>
                    <td data-th="學校名稱">
                      <p>
                        <span>{{ ExchangeSchool.SchoolName }}</span>
                        <span>{{ ExchangeSchool.SchoolName_EN }}</span>
                      </p>
                    </td>
                    <td data-th="上學期名額" class="basis_80">{{ ExchangeSchool.ProjectChangeCount_A }}</td>
                    <td data-th="下學期名額" class="basis_80">{{ ExchangeSchool.ProjectChangeCount_B }}</td>
                    <td data-th="一學年名額" class="basis_80">{{ ExchangeSchool.ProjectChangeCount_C }}</td>
                    <td data-th="詳細說明" class="basis_90">
                      <div class="circle" :class="viewdetail == ExchangeSchool.CSId ? 'add' : ''"
                        @click="showDetail(ExchangeSchool.CSId, ExchangeSchool.ProjectOtherContent)"
                        v-if="ExchangeSchool.ProjectContent || ExchangeSchool.ProjectOtherContent.length > 0">
                        <img src="img/arrow_m.svg" alt="">
                      </div>
                    </td>
                  </tr>
                  <tr class="main" :class="viewdetail == ExchangeSchool.CSId ? 'add' : ''">
                    <td>
                      <ul :id="'list_' + ExchangeSchool.CSId" class="list" style="width: 100%;">
                        <li v-if="ExchangeSchool.ProjectContent" v-html="'<p>說明</p>' + ExchangeSchool.ProjectContent">
                        </li>
                        <li v-if="ExchangeSchool.ProjectOtherContent.length > 0" v-for="OtherContent in ExchangeSchool.ProjectOtherContent"
                          v-html="'<p>' + OtherContent.Title + '</p>' + OtherContent.Content">
                        </li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="total_box">
              <p>合計：{{ CountryCount }}國</p>
              <p>計畫數：{{ ExchangeSchoolCount }}</p>
            </div>
          </div>
          <paginate v-model="page" :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
          </paginate>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Select2 from 'v-select2-component';

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "ExchangeSchool",
  components: {
    Head,
    Footer,
    Select2
  },
  data() {
    return {
      ExchangeSchoolData: null,
      Countrys: null,
      CountryId: "-1",
      Events: null,
      EId: -1,
      ExchangeSchools: null,
      local: "zh_tw",
      pageAll: 0,
      perPage: 5,
      ExchangeSchoolCount: 0,
      CountryCount: 0,
      page: 1,
      viewdetail: -1,
      ProjectOtherContent: null,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/ExchangeSchool",
        {
          params: { lang: $cookies.get("is_site_lang") },
        }
      )
      .then((response) => {
        this.ExchangeSchoolData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    showDetail: function (CSId, ProjectOtherContent) {
      // if (ProjectOtherContent && ProjectOtherContent != '[]') {
      //   this.ProjectOtherContent = JSON.parse(ProjectOtherContent);
      // } else {
      //   this.ProjectOtherContent = [];
      // }
      $(".list").stop().slideUp();
      if (this.viewdetail == CSId) {
        this.viewdetail = -1;
      } else {
        this.viewdetail = CSId;
        $("#list_" + CSId).stop().slideToggle();
      }
    },
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/ExchangeSchoolList",
        type: "post",
        data: {
          CountryId: that.CountryId,
          EId: that.EId,
          page: page,
          perPage: that.perPage,
          lang: $cookies.get("is_site_lang"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.ExchangeSchools = res.ExchangeSchools;
            $.each(that.ExchangeSchools, function (key, value) {
              if (value.ProjectOtherContent && value.ProjectOtherContent != '[]') {
                value.ProjectOtherContent = JSON.parse(value.ProjectOtherContent);
              } else {
                value.ProjectOtherContent = [];
              }
            });
            that.Countrys = res.Countrys;
            that.Events = res.Events;
            that.ExchangeSchoolCount = res.ExchangeSchoolCount;
            that.CountryCount = res.CountryCount;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            that.page = page;
            that.showDetail(-1);
          }
        },
      });
    },
  },
};
</script>

