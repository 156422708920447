<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">締約學校</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper sister_schools" v-if="SisterSchoolsData">
      <section class="world">
        <div class="container">
          <div class="title_box">
            <img src="img/title_icon5.svg" alt="">
            <h1>締約學校 List of Sister Schools</h1>
          </div>
          <!-- 20240328 新增 ↓ -->
          <div class="statistics_box">
            <ul class="list">
              <li>
                <p class="number ">{{ SisterSchoolsData.ContractSchoolCount }}</p>
                <p>締約學校</p>
              </li>
              <li>
                <p class="number ">{{ SisterSchoolsData.ContractSchoolContractCount }}</p>
                <p>締約合約</p>
              </li>
              <li>
                <p class="number ">{{ SisterSchoolsData.CountryCount }}</p>
                <p>國家</p>
              </li>
              <li>
                <p class="number ">{{ SisterSchoolsData.NumberOfInCount }}</p>
                <p>境外學位生數</p>
              </li>
            </ul>
          </div>
          <div class="text" v-html="SisterSchoolsData.SisterSchool_Text">
          </div>
          <div class="map">
            <img src="/img/world.svg" alt="">
            <!-- <img src="img/world.svg" alt="">
            <div class="airplane aa"></div>
            <div class="airplane bb"></div>
            <div class="airplane cc"></div>
            <div class="airplane dd"></div> -->
            <ul class="box">
              <li :class="Continent == '歐洲' ? 'active' : ''" @click="Continent = '歐洲'">
                <div class="europe">
                  <div class="circle">
                    <h2>{{ $t('歐洲') }}</h2>
                    <ul class="list">
                      <li>
                        <p>締約學校</p>
                        <p>{{ SisterSchoolsData.EuropeCount }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li :class="Continent == '亞洲' ? 'active' : ''" @click="Continent = '亞洲'">
                <div class="asia">
                  <div class="circle">
                    <h2>{{ $t('亞洲') }}</h2>
                    <ul class="list">
                      <li>
                        <p>締約學校</p>
                        <p>{{ SisterSchoolsData.AsiaCount }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <!-- <li :class="Continent == '北美洲' ? 'active' : ''" @click="Continent = '北美洲'">
                <div class="north">
                  <div class="circle">
                    <h2>{{ $t('北美洲') }}</h2>
                    <ul class="list">
                      <li>
                        <p>締約學校</p>
                        <p>{{ SisterSchoolsData.NorthAmericaCount }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li> -->
              <li :class="Continent == '大洋洲' ? 'active' : ''" @click="Continent = '大洋洲'">
                <div class="oceania">
                  <div class="circle">
                    <h2>{{ $t('大洋洲') }}</h2>
                    <ul class="list">
                      <li>
                        <p>締約學校</p>
                        <p>{{ SisterSchoolsData.OceaniaCount }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li :class="Continent == '美洲' ? 'active' : ''" @click="Continent = '美洲'">
                <div class="central">
                  <div class="circle">
                    <h2>{{ $t('美洲') }}</h2>
                    <ul class="list">
                      <li>
                        <p>締約學校</p>
                        <p>{{ SisterSchoolsData.AmericaCount }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <!-- <li :class="Continent == '南美洲' ? 'active' : ''" @click="Continent = '南美洲'">
                <div class="south">
                  <div class="circle">
                    <h2>{{ $t('南美洲') }}</h2>
                    <ul class="list">
                      <li>
                        <p>締約學校</p>
                        <p>{{ SisterSchoolsData.SouthAmericaCount }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li> -->
            </ul>
          </div>
          <div class="bg_box">
            <div class="bg"></div>
            <div class="bg"></div>
          </div>
          <div class="school_list show">
            <div class="title">
              <h2>{{ Continent }}</h2>
            </div>
            <div class="btn_box" v-if="Countrys">
              <button :class="CountryId == Country.CountryId ? 'active' : ''" v-for="Country in Countrys"
                :key="Country.CountryId" @click="CountryId = Country.CountryId">
                <p>{{ Country.Country }}</p>
              </button>
            </div>
            <!-- 20240329 新增搜尋 -->
            <div class="search_box">
              <div class="form-group">
                <select class="form-control" v-model="Continent">
                  <option value="歐洲">歐洲</option>
                  <option value="亞洲">亞洲</option>
                  <option value="大洋洲">大洋洲</option>
                  <option value="美洲">美洲</option>
                </select>
              </div>
              <div class="form-group">
                <input type="" class="form-control" id="" aria-describedby="" placeholder="關鍵字搜尋" v-model="SearchText" v-on:keyup.enter="loadList(1)">
              </div>
              <div class="search_btn">
                <button @click="loadList(1)">搜尋</button>
              </div>
            </div>
            <div class="list show">
              <table>
                <thead>
                  <tr>
                    <th class="basis_100">{{ $t('國家') }}</th>
                    <th class="basis_100" v-if="CountryId == 'A00'">{{ $t('省份') }}</th>
                    <th>{{ $t('學校名稱') }}</th>
                    <th>{{ $t('學校英文名稱') }}</th>
                    <th class="basis_100">{{ $t('締約層級') }}</th>
                    <th class="basis_100">{{ $t('簽約日') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="Contract in Contracts" :key="Contract.CSCId">
                    <td data-th="國家" class="basis_100">{{ Contract.Country }}</td>
                    <td data-th="省份" class="basis_100" v-if="CountryId == 'A00'">{{ Contract.Province }}</td>
                    <td data-th="學校名稱">
                      <a v-if="Contract.SchoolUrl != ''" :href="Contract.SchoolUrl" target="_blank">
                        <span>{{ Contract.SchoolName }}</span>
                      </a>
                      <span v-if="Contract.SchoolUrl == ''">{{ Contract.SchoolName }}</span>
                    </td>
                    <td data-th="學校英文名稱">{{ Contract.SchoolName_EN }}
                    </td>
                    <td data-th="締約層級" class="basis_100">{{ Contract.ContractLevel }}</td>
                    <td data-th="簽約日" class="basis_100">{{ Contract.SigningDate | timeString("YYYY-MM-DD") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
              :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
              :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
              :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
            </paginate>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
    "亞洲": "亞洲",
    "歐洲": "歐洲",
    "大洋洲": "大洋洲",
    "北美洲": "北美洲",
    "中美洲": "中美洲",
    "南美洲": "南美洲",
    "美洲": "美洲",
    "國家": "國家",
    "省份": "省份",
    "學校名稱": "學校名稱",
    "學校英文名稱": "學校英文名稱",
    "締約層級": "締約層級",
    "簽約日": "簽約日",
  },
  en: {
    "亞洲": "Asia",
    "歐洲": "Europe",
    "大洋洲": "Oceania",
    "北美洲": "North America",
    "中美洲": "Central America",
    "南美洲": "South America",
    "美洲": "America",
    "國家": "Country",
    "省份": "Province",
    "學校名稱": "Name",
    "學校英文名稱": "Name",
    "締約層級": "Contracting Level",
    "簽約日": "Signing Day",
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  i18n,
  name: "SisterSchools",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      SisterSchoolsData: null,
      Countrys: null,
      CountryId: "",
      Contracts: null,
      local: "zh_tw",
      pageAll: 0,
      perPage: 5,
      Continent: "歐洲",
      SearchText: "",
    };
  },
  watch: {
    Continent(newVal, oldVal) {
      if (this.CountryId == "") {
        this.loadList(1);
      } else {
        this.CountryId = "";
      }
    },
    CountryId(newVal, oldVal) {
      this.loadList(1);
    },
  },
  created: function () {
    if (this.$route.params.Continent) {
      this.Continent = this.$route.params.Continent;
    }
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/SisterSchools",
        {
          params: { lang: $cookies.get("is_site_lang") },
        }
      )
      .then((response) => {
        this.SisterSchoolsData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/ContractList",
        type: "post",
        data: {
          Continent: that.Continent,
          CountryId: that.CountryId,
          SearchText: that.SearchText,
          page: page,
          perPage: that.perPage,
          lang: $cookies.get("is_site_lang"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.Countrys = res.Countrys;
            that.Contracts = res.Contracts;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
  },
};
</script>
