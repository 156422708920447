<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">忘記密碼</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper login_box form forget">
      <section class="container">
        <div class="login">
          <h1>忘記密碼 forget</h1>
          <div class="box">
            <div class="form-group full">
              <input type="email" class="form-control" id="" aria-describedby="" placeholder="請輸入註冊時的帳號" v-model="Email"
                :disabled="pincodeCheck">
              <label for=""><span>帳號</span>Email</label>
            </div>
          </div>
          <div class="btn_box">
            <button v-if="!pincodeCheck" @click="getPinCode()">
              {{ pincodeTitle }}
            </button>
          </div>
          <div class="box" v-if="pincode">
            <div class="form-group full">
              <input type="text" class="form-control" id="" aria-describedby="" placeholder="請輸入驗證碼"
                v-model="pincode_input" @input="pincode_input_onInput" :disabled="pincodeCheck">
              <label for=""><span>驗證碼</span>pincode</label>
            </div>
          </div>
          <div class="box" v-if="pincodeCheck">
            <div class="form-group full">
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請輸入新密碼"
                v-model="Password">
              <label for=""><span>新密碼</span>password</label>
            </div>
          </div>
          <div class="box" v-if="pincodeCheck">
            <div class="form-group full">
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請再次輸入新密碼"
                v-model="PasswordConfirm">
              <label for=""><span>確認新密碼</span>password</label>
            </div>
          </div>
          <div class="btn_box" v-if="pincodeCheck">
            <!-- <router-link to="/" tag="button">返回登入</router-link> -->
            <button @click="forget()">重設密碼</button>
          </div>
          <hr>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Forget",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      local: "zh_tw",
      Email: "",
      Password: "",
      PasswordConfirm: "",
      pincode_input: "",
      pincode: "",
      pincodeCheck: false,
      pincodeTitle: "發送驗證碼",
      pincodeTime: 0,
      pincode_Email: "",
    };
  },
  watch: {
    Email(newVal, oldVal) {
      if (newVal != this.pincode_Email) {
        this.pincode = "";
        this.pincodeCheck = false;
      }
    },
  },
  beforeCreate: function () {
    if ($cookies.isKey("is_login_token")) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  created: function () {
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    pincode_input_onInput() {
      if (this.pincode_input != "" && this.pincode_input == this.pincode) {
        this.pincodeCheck = true;
      } else {
        this.pincodeCheck = false;
      }
    },
    async getPinCode() {
      if (this.pincodeTime > 0) {
        Swal.fire({
          title: "請稍候",
          html: "重新獲取驗證碼需等待 <span>" + this.pincodeTime + "</span> 秒",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.Email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入Email",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.Email)) {
        Swal.fire({
          title: "錯誤",
          text: "Email格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.Email + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetPinCode",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            var strInfo = AES.decrypt(res.PinCode).split("|");
            that.pincode = strInfo[0];
            that.pincode_Email = strInfo[1];
            // 倒數
            that.pincodeTime = 60;
            that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
            var loop = window.setInterval(function () {
              that.pincodeTime -= 1;
              that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
              if (that.pincodeTime == 0) {
                window.clearInterval(loop);
              }
            }, 1000);
            Swal.fire({
              title: "已發送驗證碼",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    async getRecaptchaToken() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      return token;
    },
    async forget() {
      if (this.Email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入Email",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.Email)) {
        Swal.fire({
          title: "錯誤",
          text: "Email格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if ((this.Password == "")) {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (this.PasswordConfirm != this.Password) {
        Swal.fire({
          title: "錯誤",
          text: "兩次輸入密碼必須相同",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.Email + "|" + this.Password + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ResetPassword",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "重設密碼完成",
              text: "即將返回首頁",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 3000,
              willClose: () => {
                that.$router.push({
                  name: "Home",
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

