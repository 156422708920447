<template>
  <!-- 頭 -->
  <nav class="navbar navbar-expand-lg" v-if="HeadData">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img src="/img/Logo.png" alt="" v-if="!HeadData || !HeadData.SiteLogo" />
        <img :src="HeadData.SiteLogo" alt="" v-if="HeadData && HeadData.SiteLogo" />
      </router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="line1"></span>
        <span class="line2"></span>
        <span class="line3"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="HeadData && HeadData.Header_Menu">
        <ul class="submenu">
          <li v-for="(MenuData, index) in HeadData.RightTop_Menu" :key="index">
            <a v-if="MenuData.Menu.Type == 0" href="javascript:void(0)">{{
    MenuData.Menu.Title
  }}</a>
            <a v-if="MenuData.Menu.Type == 1" href="javascript:void(0)" @click="gotoCategoryByCId(MenuData.Menu.CId)">{{
    MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 2" href="javascript:void(0)" @click="gotoPostByPId(MenuData.Menu.PId)">{{
    MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 3" href="javascript:void(0)"
              @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)">{{ MenuData.Menu.Title }}</a>
          </li>
          <li v-if="!HeadData.User">
            <div class="btn_box">
              <router-link :to="{ name: 'Login' }" tag="button">登入</router-link>
              <router-link :to="{ name: 'Register' }" tag="button">註冊</router-link>
            </div>
          </li>
          <li v-if="HeadData.User">
            <div class="btn_box">
              <router-link :to="{ name: 'Member' }" tag="button">{{ HeadData.User.Name }}</router-link>
              <button @click="logout()">登出</button>
            </div>
          </li>
          <li class="language_box">
            <a href="javascript:void(0)" @click="changeLang('en')" v-if="local == 'zh_tw'">
              <p>English</p>
            </a>
            <a href="javascript:void(0)" @click="changeLang('zh_tw')" v-if="local == 'en'">
              <p>中文</p>
            </a>
          </li>
          <!-- 20240410 新增 搜尋 -->
          <li class="search_button">
            <button type="button" class="" data-toggle="modal" data-target="#searchModal"></button>
          </li>
        </ul>
        <ul class="navbar-nav menu">
          <li class="nav-item" v-for="(MenuData, index) in HeadData.Header_Menu" :key="index">
            <a v-if="MenuData.Menu.Type == 1" class="nav-link" href="javascript:void(0)"
              @click="gotoCategoryByCId(MenuData.Menu.CId)">{{ MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 2" class="nav-link" href="javascript:void(0)"
              @click="gotoPostByPId(MenuData.Menu.PId)">{{ MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 3" class="nav-link" href="javascript:void(0)"
              @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)">{{ MenuData.Menu.Title }}</a>
            <a class="nav-link dropdown-toggle" href="#" title="" role="button" data-toggle="dropdown"
              aria-expanded="false" v-if="MenuData.Menu.Type == 0">
              {{ MenuData.Menu.Title }}
            </a>
            <div class="dropdown-menu" v-if="MenuData.Menu.Type == 0">
              <a v-for="(MenuData_Sub, index) in MenuData.SubMenus" class="dropdown-item" href="javascript:void(0)"
                title="" @click="goto(MenuData_Sub)">
                {{ MenuData_Sub.Title }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 20240410 新增 搜尋 彈跳視窗 -->
    <div class="modal fade search" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="search_box">
              <div class="form-group">
                <input type="text" class="form-control" id="" aria-describedby="" placeholder="關鍵字搜尋" v-model="Keyword">
              </div>
              <div class="search_btn">
                <button @click="gotoSearch()">搜尋</button>
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Head",
  components: {
  },
  data() {
    return {
      HeadData: null,
      local: "zh_tw",
      Keyword: "",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Head",
        {
          params: {
            token: $cookies.get("is_login_token"),
            lang: $cookies.get("is_site_lang")
          },
        }
      )
      .then((response) => {
        this.HeadData = response.data;
        if ($cookies.isKey("is_login_token") && !this.HeadData.User) {
          // 帳號在其他地方登入過
          Swal.fire({
            title: "帳號已登出",
            text: "您已在其他地方進行登入",
            icon: "info",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              $cookies.remove("is_login_token");
              if (this.$route.fullPath == "/") {
                location.reload();
              } else {
                this.$router.push({
                  name: "Home",
                });
              }
            },
          });
        }
        // 漢堡紐
        $("nav .navbar-toggler").click(function () {
          $("nav .navbar-toggler span").toggleClass("add");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  methods: {
    changeLang(lang) {
      $cookies.set("is_site_lang", lang, "30d");
      location.reload();
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    goto: function (MenuData) {
      if (MenuData.Type == 1) {
        this.gotoCategoryByCId(MenuData.CId);
      } else if (MenuData.Type == 2) {
        this.gotoPostByPId(MenuData.PId);
      } else if (MenuData.Type == 3) {
        this.gotoUrl(MenuData.Url, MenuData.Blank);
      }
    },
    logout: function () {
      $cookies.remove("is_login_token");
      if (this.$route.fullPath == "/") {
        location.reload();
      } else {
        this.$router.push({
          name: "Home",
        });
      }
    },
    gotoSearch: function () {
      if (this.Keyword) {
        $("#searchModal").modal('toggle');
        this.$router.push({
          name: "Search",
          params: { Keyword: this.Keyword },
        });
      } else {
        Swal.fire({
            title: "請輸入關鍵字",
            text: "請輸入關鍵字後再試一次",
            icon: "info",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
      }
    },
  },
};
</script>