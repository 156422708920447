<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">登入</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper login_box form">
      <section class="container">
        <div class="login">
          <h1>登入 Login</h1>
          <div class="box">
            <div class="form-group full">
              <input type="email" class="form-control" id="" aria-describedby="" placeholder="請輸入帳號" v-model="Email">
              <label for=""><span>帳號</span>Email</label>
            </div>
            <div class="form-group full">
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請輸入密碼"
                v-model="Password">
              <label for=""><span>密碼</span>password</label>
            </div>
          </div>
          <div v-if="LoginData" v-html="LoginData.LoginInfo"></div>
          <div class="btn_box">
            <button @click="login()">登入</button>
            <router-link :to="{ name: 'Register' }" tag="button">註冊</router-link>
          </div>
          <hr>
          <router-link :to="{ name: 'Forget' }" class="forget">忘記密碼？</router-link>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Login",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      LoginData: null,
      local: "zh_tw",
      Email: "",
      Password: "",
    };
  },
  beforeCreate: function () {
    if ($cookies.isKey("is_login_token")) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Login",
        {
          params: {},
        }
      )
      .then((response) => {
        this.LoginData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    async getRecaptchaToken() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      return token;
    },
    async login() {
      if (this.Email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入Email",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.Email)) {
        Swal.fire({
          title: "錯誤",
          text: "Email格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.Password == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.Email + "|" + this.Password + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/Login",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            $cookies.set("is_login_token", res.LoginToken, "30d");
            Swal.fire({
              title: "登入成功",
              text: "即將返回首頁",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                if (that.$route.fullPath == "/") {
                  location.reload();
                } else {
                  that.$router.push({
                    name: "Home",
                  });
                }
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

