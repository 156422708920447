<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">網站導覽</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper list_1 sitemap">
      <section class="container">
        <div class="">
          <div class="title_box">
            <img src="img/title_icon_sitemap.svg" alt="">
            <h1>網站導覽 Sitemap</h1>
          </div>
          <ul class="list" v-if="SiteMapData" v-html="SiteMapData.Content">
          </ul>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "SiteMap",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      SiteMapData: null,
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/SiteMap",
        {
          params: {
            lang: $cookies.get("is_site_lang")
          },
        }
      )
      .then((response) => {
        this.SiteMapData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
  },
};
</script>

