var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Head'),(_vm.PostData)?_c('nav',{staticClass:"breadcrumb_box"},[_c('div',{staticClass:"container"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("首頁")])],1),(_vm.PostData.Category)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'Category', params: { CId: _vm.PostData.Category.CId } }}},[_vm._v(" "+_vm._s(_vm.PostData.Category.CategoryName))])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.PostData.Post.Title))])])])]):_vm._e(),(_vm.PostData)?_c('div',{staticClass:"wrapper list_1 list_main"},[_c('section',{staticClass:"news"},[_c('div',{staticClass:"bg"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title_box"},[_c('img',{attrs:{"src":"img/arrow_m_2.svg","alt":""}}),_c('h1',[_vm._v(_vm._s(_vm.PostData.Post.Title))])]),_c('div',{staticClass:"link_btn"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeString")(_vm.PostData.Post.PublicStartTime,"YYYY-MM-DD")))]),_c('ul',{staticClass:"link"},[_c('li',[_c('a',{attrs:{"href":_vm.PostData.ShareUrl_Facebook,"target":"_blank","title":"分享至Facebook, 另開新視窗"}},[_c('img',{attrs:{"src":"img/icon_fb.svg","alt":""}})])]),_c('li',[_c('a',{attrs:{"href":_vm.PostData.ShareUrl_Twitter,"target":"_blank","title":"分享至X, 另開新視窗"}},[_c('img',{attrs:{"src":"img/icon_twitter.svg","alt":""}})])])])]),(_vm.Slideshows)?_c('slick',{staticClass:"carousel_box",attrs:{"options":{
            dots: true,
            autoplay: true,
            autoplaySpeed: 3000,
          }}},_vm._l((_vm.Slideshows),function(Slideshow,index){return _c('div',{key:index,staticClass:"carousel"},[_c('div',{staticClass:"box"},[_c('a',{attrs:{"href":_vm.PostData.SiteUrl + Slideshow.Url,"target":"_blank"}},[_c('img',{attrs:{"src":_vm.PostData.SiteUrl + Slideshow.Url,"alt":Slideshow.DisplayName}})])])])}),0):_vm._e(),_c('div',{staticClass:"text_box",domProps:{"innerHTML":_vm._s(_vm.PostData.Post.Content)}}),(_vm.Attachments)?_c('div',{staticClass:"download_box"},[_c('h2',[_vm._v("檔案下載")]),_c('ul',{staticClass:"download"},_vm._l((_vm.Attachments),function(Attachment,index){return _c('li',{key:index},[_c('p',[_c('span',[_vm._v(_vm._s(Attachment.DisplayName))]),_c('a',{attrs:{"href":_vm.PostData.SiteUrl +
                  '/Platform/Html/Download?FId=' +
                  Attachment.FId,"target":"_blank","title":'下載' + Attachment.DisplayName + ', 另開新視窗'}},[_vm._v("Download")])])])}),0)]):_vm._e()],1)])])]):_vm._e(),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }