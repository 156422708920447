<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">個人資料</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper member form" v-if="MemberData">
      <div class="container">
        <div class="btn">
          <button :class="tabindex == 2 ? 'active' : ''" @click="tabindex = 2">
            <p>申請查詢</p>
          </button>
          <button :class="tabindex == 1 ? 'active' : ''" @click="tabindex = 1">
            <p>個人資料</p>
          </button>
        </div>
        <div class="box" :class="tabindex == 1 ? 'show' : ''">
          <div class="personal">
            <div class="form-group full">
              <input type="email" class="form-control" id="" aria-describedby="" :placeholder="MemberData.User.Email"
                disabled>
              <label for=""><span>帳號</span>Email</label>
            </div>
            <hr>
            <div class="btn">
              <button v-if="!pincodeCheck" @click="getPinCode()">
                {{ pincodeTitle }}
              </button>
            </div>
            <div class="form-group full" v-if="pincode">
              <input type="text" class="form-control" id="" aria-describedby="" placeholder="請輸入驗證碼"
                v-model="pincode_input" @input="pincode_input_onInput" :disabled="pincodeCheck">
              <label for=""><span>驗證碼</span>pincode</label>
            </div>
            <div class="form-group full" v-if="pincodeCheck">
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請輸入新密碼"
                v-model="Password">
              <label for=""><span>新密碼</span>password</label>
            </div>
            <div class="form-group full" v-if="pincodeCheck">
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請再次輸入新密碼"
                v-model="PasswordConfirm">
              <label for=""><span>確認新密碼</span>password</label>
            </div>
            <div class="btn" v-if="pincodeCheck">
              <button @click="forget()">重設密碼</button>
            </div>
          </div>
        </div>
        <div class="box" :class="tabindex == 2 ? 'show' : ''">

          <div class="apply_box" v-for="Apply in MemberData.Applys" :key="Apply.AId">
            <h1 v-if="Apply.ApplyType == 1">外籍學位生申請</h1>
            <h1 v-if="Apply.ApplyType == 2">外籍交換生申請</h1>
            <div class="apply">
              <ul class="progress_box">
                <li :class="Apply.ApprovalStatus == 0 ? 'active' : ''">
                  <div class="circle">
                    <div class="img_box">
                      <img src="img/member_icon2.svg" alt="">
                    </div>
                  </div>
                  <p>審核中</p>
                </li>
                <li :class="Apply.ApprovalStatus == 2 ? 'active' : ''">
                  <div class="circle">
                    <div class="img_box">
                      <img src="img/member_icon1.svg" alt="">
                    </div>
                  </div>
                  <p>需補件</p>
                </li>
                <li v-if="Apply.ApprovalStatus == 0 || Apply.ApprovalStatus == 2">
                  <div class="circle">
                    <div class="img_box">
                    </div>
                  </div>
                  <p>...</p>
                </li>
                <li :class="Apply.ApprovalStatus == 1 ? 'active' : ''" v-if="Apply.ApprovalStatus == 1">
                  <div class="circle">
                    <div class="img_box">
                      <img src="img/member_icon3.svg" alt="">
                    </div>
                  </div>
                  <p>審核成功</p>
                </li>
                <li :class="Apply.ApprovalStatus == 3 ? 'active' : ''" v-if="Apply.ApprovalStatus == 3">
                  <div class="circle">
                    <div class="img_box">
                      <img src="img/member_icon4.svg" alt="">
                    </div>
                  </div>
                  <p>審核失敗</p>
                </li>
              </ul>
              <ul class="list">
                <li>
                  <p>活動名稱</p>
                  <p>{{ Apply.Event.Title }}</p>
                </li>
                <li v-if="Apply.Event.ApplyType != 3">
                  <p>申請人</p>
                  <p>{{ Apply.Name }} / {{ Apply.Name_EN }}</p>
                </li>
                <li v-if="Apply.Event.ApplyType == 3">
                  <p>申請人</p>
                  <p>{{ Apply.T3_Name }} / {{ Apply.T3_Name_EN_First }} {{ Apply.T3_Name_EN_Last }}</p>
                </li>
                <li>
                  <p>申請日期</p>
                  <p>{{ Apply.CreateTime | timeString("YYYY-MM-DD") }}</p>
                </li>
                <li v-if="Apply.Event.ApplyType != 3">
                  <p>申請系所</p>
                  <p>{{ Apply.Program }}</p>
                </li>
                <li>
                  <div class="btn success_btn">
                    <router-link :to="{ name: 'ForeignContact', params: { AId: Apply.AId } }" tag="button">在國外聯絡資訊</router-link>
                    <router-link style="margin-left: 5px;" :to="{ name: 'ForeignReport', params: { AId: Apply.AId } }" tag="button">回國報告</router-link>
                    <router-link style="margin-left: 5px;" v-if="Apply.ApplyType == 3" :to="{ name: 'ApplyPrint', params: { AId: Apply.AId } }"
                      tag="button">列印</router-link>
                    <router-link style="margin-left: 5px;" v-if="Apply.ApprovalStatus == 2" :to="{ name: 'Apply', params: { ApplyType: Apply.ApplyType, AId: Apply.AId } }"
                      tag="button">補件</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Register",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      MemberData: null,
      local: "zh_tw",
      tabindex: 2,
      Password: "",
      PasswordConfirm: "",
      pincode_input: "",
      pincode: "",
      pincodeCheck: false,
      pincodeTitle: "發送密碼重置驗證碼",
      pincodeTime: 0,
      pincode_Email: "",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Member",
        {
          params: {
            token: $cookies.get("is_login_token")
          },
        }
      )
      .then((response) => {
        this.MemberData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    pincode_input_onInput() {
      if (this.pincode_input != "" && this.pincode_input == this.pincode) {
        this.pincodeCheck = true;
      } else {
        this.pincodeCheck = false;
      }
    },
    async getPinCode() {
      if (this.pincodeTime > 0) {
        Swal.fire({
          title: "請稍候",
          html: "重新獲取驗證碼需等待 <span>" + this.pincodeTime + "</span> 秒",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.MemberData.User.Email + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetPinCode",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            var strInfo = AES.decrypt(res.PinCode).split("|");
            that.pincode = strInfo[0];
            that.pincode_Email = strInfo[1];
            // 倒數
            that.pincodeTime = 60;
            that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
            var loop = window.setInterval(function () {
              that.pincodeTime -= 1;
              that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
              if (that.pincodeTime == 0) {
                window.clearInterval(loop);
              }
            }, 1000);
            Swal.fire({
              title: "已發送驗證碼",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    async getRecaptchaToken() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      return token;
    },
    async forget() {
      if ((this.Password == "")) {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (this.PasswordConfirm != this.Password) {
        Swal.fire({
          title: "錯誤",
          text: "兩次輸入密碼必須相同",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.MemberData.User.Email + "|" + this.Password + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ResetPassword",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "重設密碼完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 3000,
              willClose: () => {
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

