<template>
  <div>
    <Head />

    <nav class="breadcrumb_box" v-if="PostData">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item" v-if="PostData.Category">
            <router-link :to="{ name: 'Category', params: { CId: PostData.Category.CId } }"> {{
              PostData.Category.CategoryName }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ PostData.Post.Title }}</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper list_1 list_main" v-if="PostData">
      <!-- 最新消息 -->
      <section class="news">
        <div class="bg">
          <div class="container">
            <div class="title_box">
              <img src="img/arrow_m_2.svg" alt="">
              <h1>{{ PostData.Post.Title }}</h1>
            </div>
            <div class="link_btn">
              <p class="time">{{ PostData.Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
              <ul class="link">
                <li>
                  <a :href="PostData.ShareUrl_Facebook" target="_blank" title="分享至Facebook, 另開新視窗">
                    <img src="img/icon_fb.svg" alt="">
                  </a>
                </li>
                <li>
                  <a :href="PostData.ShareUrl_Twitter" target="_blank" title="分享至X, 另開新視窗">
                    <img src="img/icon_twitter.svg" alt="">
                  </a>
                </li>
                <!-- <li>
                  <a href="">
                    <img src="img/icon_share.svg" alt="">
                  </a>
                </li> -->
              </ul>
            </div>
            <slick class="carousel_box" v-if="Slideshows" :options="{
              dots: true,
              autoplay: true,
              autoplaySpeed: 3000,
            }">
              <div class="carousel" v-for="(Slideshow, index) in  Slideshows " :key="index">
                <div class="box">
                  <a :href="PostData.SiteUrl + Slideshow.Url" target="_blank">
                    <img :src="PostData.SiteUrl + Slideshow.Url" :alt="Slideshow.DisplayName" />
                  </a>
                </div>
              </div>
            </slick>
            <div class="text_box" v-html="PostData.Post.Content">
            </div>
            <div class="download_box" v-if="Attachments">
              <h2>檔案下載</h2>
              <ul class="download">
                <li v-for="( Attachment, index ) in  Attachments " :key="index">
                  <p><span>{{ Attachment.DisplayName }}</span><a :href="PostData.SiteUrl +
                    '/Platform/Html/Download?FId=' +
                    Attachment.FId
                    " target="_blank" :title="'下載' + Attachment.DisplayName + ', 另開新視窗'">Download</a></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import Slick from "vue-slick";

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Post",
  components: {
    Head,
    Footer,
    Slick
  },
  data() {
    return {
      PostData: null,
      Attachments: null,
      Slideshows: null,
      local: "zh_tw",
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: to.params.PId, CId: to.params.CId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        } else {
          this.Attachments = null;
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        } else {
          this.Slideshows = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: this.$route.params.PId, CId: this.$route.params.CId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
  },
};
</script>

